
export interface IAnimationStepsInfo {
  key: string;
  from: number;
  to: number;
  type?: 's' | 't';
}
export interface IAnimationStepData {
  step: number;
  inverse: number;
}

export const   getAnimationSteps = (ranges: IAnimationStepsInfo[], reverse = false, frames: number): Map<string, IAnimationStepData>[] =>  {
  const  ease = (v, pow = 4) => {
    return 1 - Math.pow(1 - v, pow);
  };
  const steps = Array(frames)
    .fill(null)
    .map((e, i) => (reverse ? i + 1 : frames - (i + 1)))
    .map((keyFrame, i, arr) => {
      const obj = new Map<string, IAnimationStepData>();
      for (const range of ranges) {
        let step;
        let inverseStep;
        if (range.type === 't') {
          const r = (range.to - range.from) / frames;
          step = range.from + (r * keyFrame);
          inverseStep = range.to - (r * keyFrame);
        } else {
          const r = range.to / range.from;
          const easedStep = ease(keyFrame / frames);
          step = r + (1 - r) * easedStep;
          step = r + (1 - r) * keyFrame / frames;
          inverseStep = 1 / step;
        }
        obj.set(range.key, {step: step, inverse: inverseStep});
      }
      return obj;
    });

  return steps;
};
